import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "urls", "cloudinaryIds", "takenAt", "submitButton" ]
    static values = {
        imageCount: Number,
        imageSentCount: Number
    }

    uploadAdded(event) {
        this.imageCountValue = this.imageCountValue + 1
    }

    imageUploaded(event) {
        console.log(this.imageCountValue)
        this.urlsTarget.value += event.detail.secure_url + '\t'
        this.cloudinaryIdsTarget.value += event.detail.public_id + '\t'
        this.imageSentCountValue = this.imageSentCountValue + 1
        if (this.imageCountValue == this.imageSentCountValue) {
            this.submitButtonTarget.disabled = false
        }
    }
}