import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["source", "preview"]

    sourceUpdated(event){
        fetch(this.sourceTarget.dataset().url)
            .then(response => response.text())
            .then(html => {
                this.previewTarget.innerHTML = html
            })

        this.sourceTarget
    }
}