import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  close(event) {
    if (this.hasModalTarget) {
      event.preventDefault()
      this.modalTarget.remove()
    }
  }

}