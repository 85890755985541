import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['input']
    connect() {
    }

    format() {
        var value = this.inputTarget.value

        var minutes = ""
        var seconds = ""
        value = value.replaceAll(':', '');
        if(value.length > 2) {
            minutes = value.slice(0, -2)
            seconds = value.slice(-2)

            this.inputTarget.value = minutes + ":" + seconds
        }
    }
}
