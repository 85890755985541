import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['toggler', 'input']
    connect() {
    }

    toggle() {
        var enabled = this.togglerTarget.checked
        if(enabled){
            this.inputTarget.classList.remove('hidden')
            // this.inputTarget.style.display = 'initial'
        } else {
            this.inputTarget.classList.add('hidden')
            // this.inputTarget.style.display = 'block'
        }
    }
}
