import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("multiautocompletes")
    this.element.addEventListener("autocomplete.change", (e) => {
      let extraData = JSON.parse(e.detail.selected.dataset.autocompleteExtraData)
      if (extraData) {

        let complete_additional_fields = e.target.dataset.completeAdditionalFields

        if (complete_additional_fields.includes("crew") && "crew" in extraData){
          let elem = e.target.parentElement.getElementsByClassName("crew")[0]
          if(elem.children[0].value == "") { elem.children[0].value = extraData.crew }
          if(elem.children[1].value == "") { elem.children[1].value = extraData.crew }
        }

        if (complete_additional_fields.includes("klass") && "klass" in extraData){
          let elem = e.target.parentElement.getElementsByClassName("klass")[0]
          if(elem.children[0].value == "") { elem.children[0].value = extraData.klass }
          if(elem.children[1].value == "") { elem.children[1].value = extraData.klass }
        }

        if (complete_additional_fields.includes("pys") && "pys" in extraData) {
          let elem = e.target.parentElement.getElementsByClassName("pys")[0]
          let klassOverride = e.target.classList.contains("klass")
          if(elem.children[0].value == "" || klassOverride) { elem.children[0].value = extraData.pys }
        }

        if (complete_additional_fields.includes("sail_number") && "sail_number" in extraData) {
          let elem = e.target.parentElement.getElementsByClassName("sail_number")[0]
          if(elem.children[0].value == "") { elem.children[0].value = extraData.sail_number }
        }
      }
    })
  }
}

